import React from "react"
import AlbumText from "../../components/AlbumText"
import Layout from "../../components/Layout"
import People from "../../components/People"
import Tracks from "../../components/Tracks"
import colors from "../../colors"
import { getAlbum } from "../../data/albums"
import AlbumCover from "../../components/AlbumCover"
import Title from "../../components/Title"

const numberTwo = getAlbum("number-two")

export default () => (
  <Layout backgroundColor={colors.white} textColor={colors.black}>
    <AlbumCover filename="number-two.png" borderColor={colors.black} />
    <Title color={colors.numberTwo.orange} shadowColor={colors.black}>
      The Wig Out: Number Two
    </Title>
    <p>
      Release Date: <strong>June 16th, 2020</strong>
    </p>
    <AlbumText>
      <p>
        Number Two is the second EP from The Wig Out, arriving three weeks after
        the debut EP,{" "}
        <a href="/press/baked-dwarves">Baked Dwarves Moan At Harvestman</a>.
      </p>
      <p>
        Adapting creative constraint practices like exquisite corpse and
        conditional drawing to our pandemic-imposed isolation, The Wig Out
        follows a unique process. Each release is recorded and mixed in a period
        of four weeks, and features a unique lineup of musicians. This album
        sees Dave Foley (Hypatia Lake) return, accompanied by six new
        collaborators including Adrian and Andrew Woods (Map of the Woulds),
        noted jazz accordionist/keyboardist Josh Hou, TQ Berg, Matt Sheldon, and
        Dan Schwartz.
      </p>
    </AlbumText>
    <Title color={colors.black} shadowColor={colors.numberTwo.orange}>
      Tracks
    </Title>
    <Tracks tracks={numberTwo.tracks} />
    <Title color={colors.black} shadowColor={colors.numberTwo.orange}>
      People
    </Title>
    <AlbumText>Recorded and mixed between April and May 2020 by:</AlbumText>
    <People album={numberTwo} />
  </Layout>
)
